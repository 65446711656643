import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  de: {
    translation: {
      	"Mein Standort":"Mein Standort",
      	"Entfernung":"Entfernung",
      	"Fahrzeit (ca.)":"Fahrzeit (ca.)",
      	"Zubehör": "Zubehör",
      	"Ausbau": "Ausbau",
      	"Reimo Fahrzeuge": "Reimo Fahrzeuge",
      	"Adria Wohnmobile, Vans & Caravans": "Adria Wohnmobile, Vans & Caravans",
      	"Sun Living": "Sun Living",
      	"Vermietung":"Vermietung",	
      	"Lippert Händler": "Lippert Händler",
      	"rvs": "Wohnmobile",
  	  	"caravans": "Wohnwagen",
  		"vans": "Vans",
  		"service": "Service",
  		"conversion": "Umbau",
  		"installation": "Einbau",
  		"accessories": "Zubehör-Handel",
  		"reimo-accessories": "Reimo Zubehör Spezialist",
	  	"accessories-pickup": "Reimo Zubehör Abholung",
  		"reimo-conversion": "Reimo Umbau",
  		"reimo-vehicles": "Reimo Fahrzeuge",
  		"adria-vans": "Adria Vans",
  		"camp4": "Camp4 Zubehör",
  		"adria-caravans": "Adria Wohnwagen",
  		"adria-rvs": "Adria Wohnmobile",
  		"adria-service": "Adria Service",
  		"sun-living": "Sun Living Wohnmobile",
  		"sun-living-service": "Sun Living Service",
  		"rvs-rental": "Wohnmobil-Vermietung",
  		"caravans-rental": "Wohnwagen-Vermietung",      
		"sunday":"Sonntag",
		"monday":"Montag",
		"tuesday":"Dienstag",
		"wednesday":"Mittwoch",
		"thursday":"Donnerstag",
		"friday":"Freitag",
		"saturday":"Samstag",
		"geschlossen": "geschlossen",   
		"nächstgelegener Händler": "nächstgelegener Händler",
		"Keine Händler gefunden.": "Keine Händler gefunden.",
		"Wegbeschreibung": "Wegbeschreibung",
		"Reimo Zubehör Händler": "Reimo Zubehör Händler",
		"searchNearMe": "In Ihrer Nähe suchen...",
		"couldNotGetLocation": "Position konnte nicht ermittelt werden.",
		"gettingLocation": "Bestimmte Position...",
		"Hinweis": "Hinweis",
		"Vergrößern":"Vergrößern",
		"Händlerliste": "Händlerliste"
    }
  },
  en: {
    translation: {
		"Mein Standort":"My location",
		"Entfernung":"Distance",
		"Fahrzeit (ca.)":"Travel time (approx.)",
		"Zubehör":"Accessories",
		"Ausbau":"Expanded",
		"Reimo Fahrzeuge":"Reimo vehicles",
		"Adria Wohnmobile, Vans & Caravans":"Adria Motorhomes, Vans & Caravans",
		"Sun Living":"Sun Living",
		"Vermietung":"Renting",
		"Lippert Händler":"Lippert dealer",
		"rvs":"Motorhomes",
		"caravans":"Caravan",
		"vans":"Vans",
		"service":"Service",
		"conversion":"Conversion",
		"installation":"Installation",
		"accessories":"Accessories trade",
		"reimo-accessories":"Reimo accessories specialist",
		"accessories-pickup":"Reimo accessories collection",
		"reimo-conversion":"Reimo conversion",
		"reimo-vehicles":"Reimo vehicles",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 Accessories",
		"adria-caravans":"Adria caravan",
		"adria-rvs":"Adria motorhomes",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living Motorhomes",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Motorhome rental",
		"caravans-rental":"Caravan rental",
		"sunday":"Sunday",
		"monday":"Monday",
		"tuesday":"Tuesday",
		"wednesday":"Wednesday",
		"thursday":"Thursday",
		"friday":"Friday",
		"saturday":"Saturday",
		"geschlossen":"closed",
		"nächstgelegener Händler":"nearest dealer",
		"Keine Händler gefunden.":"No dealers found.",
		"Wegbeschreibung":"Directions",
		"Reimo Zubehör Händler":"Reimo accessories dealers",
		"searchNearMe":"Search near you...",
		"couldNotGetLocation":"Position could not be determined.",
		"gettingLocation":"Certain position...",
		"Händlerliste":"Dealer list",
		"Vergrößern":"Enlarge",
		"Hinweis":"Note"
    }
  },
  fr: {
    translation: {
     	"Mein Standort":"Ma position",
		"Entfernung":"Distance",
		"Fahrzeit (ca.)":"Durée du trajet (environ)",
		"Zubehör":"Accessoires",
		"Ausbau":"Développe",
		"Reimo Fahrzeuge":"Véhicules Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria camping-cars, vans & caravanes",
		"Sun Living":"Vivre au soleil",
		"Vermietung":"Location",
		"Lippert Händler":"Concessionnaire Lippert",
		"rvs":"Camping-cars",
		"caravans":"Caravane",
		"vans":"Vans",
		"service":"Service",
		"conversion":"Transformation",
		"installation":"Montage",
		"accessories":"Commerce d'accessoires",
		"reimo-accessories":"Spécialiste des accessoires Reimo",
		"accessories-pickup":"Retrait des accessoires Reimo",
		"reimo-conversion":"Transformation Reimo",
		"reimo-vehicles":"Véhicules Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 Accessoires",
		"adria-caravans":"Caravane Adria",
		"adria-rvs":"Adria camping-cars",
		"adria-service":"Service Adria",
		"sun-living":"Sun Living camping-cars",
		"sun-living-service":"Service de vie au soleil",
		"rvs-rental":"Location de camping-cars",
		"caravans-rental":"Location de caravanes",
		"sunday":"Dimanche",
		"monday":"Lundi",
		"tuesday":"Mardi",
		"wednesday":"Mercredi",
		"thursday":"Jeudi",
		"friday":"Vendredi",
		"saturday":"Samedi",
		"geschlossen":"fermé",
		"nächstgelegener Händler":"distributeur le plus proche",
		"Keine Händler gefunden.":"Aucun revendeur trouvé.",
		"Wegbeschreibung":"Itinéraire",
		"Reimo Zubehör Händler":"Accessoires Reimo Revendeurs",
		"Händlerliste":"Liste des revendeurs",
"searchNearMe":"Chercher dans votre région...",
"couldNotGetLocation":"La position n'a pas pu être déterminée.",
"gettingLocation":"Position spécifique...",	
"Vergrößern":"Agrandir",	
		"Hinweis":"Remarque"
    }
  },
  it: {
    translation: {
      	"Mein Standort":"La mia posizione",
		"Entfernung":"Distanza",
		"Fahrzeit (ca.)":"Tempo di viaggio (circa)",
		"Zubehör":"Accessori",
		"Ausbau":"Espanso",
		"Reimo Fahrzeuge":"Veicoli Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria Camper, Furgoni e Caravan",
		"Sun Living":"Vivere al sole",
		"Vermietung":"Noleggio",
		"Lippert Händler":"Rivenditore Lippert",
		"rvs":"Camper",
		"caravans":"Caravan",
		"vans":"Furgoni",
		"service":"Servizio",
		"conversion":"Conversione",
		"installation":"Installazione",
		"accessories":"Commercio di accessori",
		"reimo-accessories":"Specialista in accessori Reimo",
		"accessories-pickup":"Collezione di accessori Reimo",
		"reimo-conversion":"Conversione di Reimo",
		"reimo-vehicles":"Veicoli Reimo",
		"adria-vans":"Furgoni Adria",
		"camp4":"Accessori Camp4",
		"adria-caravans":"Caravan Adria",
		"adria-rvs":"Camper Adria",
		"adria-service":"Servizio Adria",
		"sun-living":"Sun Living Motorhomes",
		"sun-living-service":"Servizio Sun Living",
		"rvs-rental":"Noleggio camper",
		"caravans-rental":"Noleggio caravan",
		"sunday":"Domenica",
		"monday":"Lunedì",
		"tuesday":"Martedì",
		"wednesday":"Mercoledì",
		"thursday":"Giovedì",
		"friday":"Venerdì",
		"saturday":"Sabato",
		"geschlossen":"chiuso",
		"nächstgelegener Händler":"rivenditore più vicino",
		"Keine Händler gefunden.":"Non sono stati trovati commercianti.",
		"Wegbeschreibung":"Indicazioni stradali",
		"Reimo Zubehör Händler":"Rivenditori di accessori Reimo",
		"searchNearMe":"Cerca nella tua zona...",
		"couldNotGetLocation":"Non è stato possibile determinare la posizione.",
		"gettingLocation":"Una certa posizione...",
		"Händlerliste":"Elenco dei rivenditori",
		"Vergrößern":"Ingrandire",
		"Hinweis":"Nota"
    }
  },
  es: {
    translation: {
		"Mein Standort":"Mi ubicación",
		"Entfernung":"Distancia",
		"Fahrzeit (ca.)":"Tiempo de viaje (aprox.)",
		"Zubehör":"Accesorios",
		"Ausbau":"Ampliado",
		"Reimo Fahrzeuge":"Vehículos Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Autocaravanas, furgonetas y caravanas Adria",
		"Sun Living":"Vivir al sol",
		"Vermietung":"Alquiler de",
		"Lippert Händler":"Distribuidor Lippert",
		"rvs":"Autocaravanas",
		"caravans":"Caravana",
		"vans":"Furgonetas",
		"service":"Servicio",
		"conversion":"Conversión",
		"installation":"Instalación",
		"accessories":"Comercio de accesorios",
		"reimo-accessories":"Especialista en accesorios Reimo",
		"accessories-pickup":"Colección de accesorios Reimo",
		"reimo-conversion":"Conversión de Reimo",
		"reimo-vehicles":"Vehículos Reimo",
		"adria-vans":"Furgonetas Adria",
		"camp4":"Accesorios Camp4",
		"adria-caravans":"Caravana Adria",
		"adria-rvs":"Autocaravanas Adria",
		"adria-service":"Servicio Adria",
		"sun-living":"Autocaravanas Sun Living",
		"sun-living-service":"Servicio Sun Living",
		"rvs-rental":"Alquiler de autocaravanas",
		"caravans-rental":"Alquiler de caravanas",
		"sunday":"Domingo",
		"monday":"Lunes",
		"tuesday":"Martes",
		"wednesday":"Miércoles",
		"thursday":"Jueves",
		"friday":"Viernes",
		"saturday":"Sábado",
		"geschlossen":"cerrado",
		"nächstgelegener Händler":"Distribuidor más cercano",
		"Keine Händler gefunden.":"No se han encontrado comerciantes.",
		"Wegbeschreibung":"Cómo llegar",
		"Reimo Zubehör Händler":"Distribuidores de accesorios Reimo",
		"searchNearMe":"Busca en tu zona...",
		"couldNotGetLocation":"No se ha podido determinar la posición.",
		"gettingLocation":"Cierta posición...",
		"Händlerliste":"Lista de distribuidores",
		"Vergrößern":"Ampliar",
		"Hinweis":"Nota"
    }
  },
  nl: {
    translation: {
     	"Mein Standort":"Mijn locatie",
		"Entfernung":"Afstand",
		"Fahrzeit (ca.)":"Reistijd (ongeveer)",
		"Zubehör":"Accessoires",
		"Ausbau":"Uitgebreid",
		"Reimo Fahrzeuge":"Reimo voertuigen",
		"Adria Wohnmobile, Vans & Caravans":"Adria Campers, bestelwagens & caravans",
		"Sun Living":"Zon Wonen",
		"Vermietung":"Huren",
		"Lippert Händler":"Lippert dealer",
		"rvs":"Campers",
		"caravans":"Caravan",
		"vans":"Vans",
		"service":"Service",
		"conversion":"Conversie",
		"installation":"Installatie",
		"accessories":"Accessoires handel",
		"reimo-accessories":"Reimo Accessoires Specialist",
		"accessories-pickup":"Reimo accessoires collectie",
		"reimo-conversion":"Reimo ombouw",
		"reimo-vehicles":"Reimo voertuigen",
		"adria-vans":"Adria bestelwagens",
		"camp4":"Camp4 Accessoires",
		"adria-caravans":"Adria caravan",
		"adria-rvs":"Adria campers",
		"adria-service":"Adria Diensten",
		"sun-living":"Sun Living Campers",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Camperverhuur",
		"caravans-rental":"Caravan huren",
		"sunday":"Zondag",
		"monday":"Maandag",
		"tuesday":"Dinsdag",
		"wednesday":"Woensdag",
		"thursday":"Donderdag",
		"friday":"Vrijdag",
		"saturday":"Zaterdag",
		"geschlossen":"gesloten",
		"nächstgelegener Händler":"dichtstbijzijnde dealer",
		"Keine Händler gefunden.":"Geen handelaren gevonden.",
		"Wegbeschreibung":"Routebeschrijving",
		"Reimo Zubehör Händler":"Reimo Accessoires Dealers",
		"searchNearMe":"Zoek in uw omgeving...",
		"couldNotGetLocation":"Positie kon niet worden bepaald.",
		"gettingLocation":"Bepaalde positie...",
		"Händlerliste":"Handelaarslijst",
		"Vergrößern":"Vergroten",
		"Hinweis":"Opmerking"
    }
  },
  pl: {
    translation: {
    	"Mein Standort":"Moja lokalizacja",
		"Entfernung":"Odległość",
		"Fahrzeit (ca.)":"Czas podróży (w przybliżeniu)",
		"Zubehör":"Akcesoria",
		"Ausbau":"Rozszerzony",
		"Reimo Fahrzeuge":"Pojazdy Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Samochody kempingowe, furgonetki i przyczepy kempingowe Adria",
		"Sun Living":"Sun Living",
		"Vermietung":"Wynajem",
		"Lippert Händler":"Dealer Lippert",
		"rvs":"Samochody kempingowe",
		"caravans":"Przyczepa kempingowa",
		"vans":"Vans",
		"service":"Usługa",
		"conversion":"Konwersja",
		"installation":"Instalacja",
		"accessories":"Handel akcesoriami",
		"reimo-accessories":"Specjalista ds. akcesoriów Reimo",
		"accessories-pickup":"Kolekcja akcesoriów Reimo",
		"reimo-conversion":"Konwersja Reimo",
		"reimo-vehicles":"Pojazdy Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Akcesoria Camp4",
		"adria-caravans":"Przyczepa kempingowa Adria",
		"adria-rvs":"Samochody kempingowe Adria",
		"adria-service":"Adria Service",
		"sun-living":"Samochody kempingowe Sun Living",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Wynajem samochodów kempingowych",
		"caravans-rental":"Wynajem przyczep kempingowych",
		"sunday":"Niedziela",
		"monday":"Poniedziałek",
		"tuesday":"Wtorek",
		"wednesday":"Środa",
		"thursday":"Czwartek",
		"friday":"Piątek",
		"saturday":"Sobota",
		"geschlossen":"zamknięty",
		"nächstgelegener Händler":"najbliższy dealer",
		"Keine Händler gefunden.":"Nie znaleziono handlowców.",
		"Wegbeschreibung":"Kierunki",
		"Reimo Zubehör Händler":"Dealerzy akcesoriów Reimo",
		"searchNearMe":"Szukaj w swojej okolicy...",
		"couldNotGetLocation":"Pozycja nie mogła zostać określona.",
		"gettingLocation":"Pewna pozycja...",
		"Händlerliste":"Lista dealerów",
		"Vergrößern":"Powiększ",

		"Hinweis":"Uwaga"
    }
  },
  da: {
    translation: {
		"Mein Standort":"Min placering",
		"Entfernung":"Afstand",
		"Fahrzeit (ca.)":"Rejsetid (ca.)",
		"Zubehör":"Tilbehør",
		"Ausbau":"Udvidet",
		"Reimo Fahrzeuge":"Reimo-køretøjer",
		"Adria Wohnmobile, Vans & Caravans":"Adria autocampere, varevogne og campingvogne",
		"Sun Living":"Sun Living",
		"Vermietung":"Leje",
		"Lippert Händler":"Lippert-forhandler",
		"rvs":"Autocampere",
		"caravans":"Campingvogn",
		"vans":"Vans",
		"service":"Service",
		"conversion":"Konvertering",
		"installation":"Installation",
		"accessories":"Handel med tilbehør",
		"reimo-accessories":"Specialist i tilbehør til Reimo",
		"accessories-pickup":"Reimos kollektion af tilbehør",
		"reimo-conversion":"Reimo-konvertering",
		"reimo-vehicles":"Reimo-køretøjer",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 tilbehør",
		"adria-caravans":"Adria campingvogn",
		"adria-rvs":"Adria autocampere",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living Autocampere",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Udlejning af autocampere",
		"caravans-rental":"Udlejning af campingvogne" ,"sunday":"Søndag",
		"monday":"Mandag",
		"tuesday":"Tirsdag",
		"wednesday":"Onsdag",
		"thursday":"Torsdag",
		"friday":"Fredag",
		"saturday":"Lørdag",
		"geschlossen":"lukket",
		"nächstgelegener Händler":"Nærmeste forhandler",
		"Keine Händler gefunden.":"Ingen forhandlere fundet.",
		"Wegbeschreibung":"Kørselsvejledning",
		"Reimo Zubehör Händler":"Forhandlere af Reimo-tilbehør",
   		"searchNearMe":"Søg i dit område...",
		"couldNotGetLocation":"Position kunne ikke bestemmes.",
		"gettingLocation":"Visse stillinger...",
		"Händlerliste":"Liste over forhandlere",
		"Vergrößern":"Forstør",

		"Hinweis":"Bemærk"
}
  },
  sv: {
    translation: {
		"Mein Standort":"Min plats",
		"Entfernung":"Avstånd",
		"Fahrzeit (ca.)":"Restid (ca)",
		"Zubehör":"Tillbehör",
		"Ausbau":"Utökad",
		"Reimo Fahrzeuge":"Reimo fordon",
		"Adria Wohnmobile, Vans & Caravans":"Adria husbilar, transportbilar och husvagnar",
		"Sun Living":"Levande sol",
		"Vermietung":"Uthyrning",
		"Lippert Händler":"Lippert Återförsäljare",
		"rvs":"Husbilar",
		"caravans":"Husvagn",
		"vans":"Vans",
		"service":"Tjänster",
		"conversion":"Omvandling",
		"installation":"Installation",
		"accessories":"Handel med tillbehör",
		"reimo-accessories":"Specialist på Reimo-tillbehör",
		"accessories-pickup":"Reimo tillbehörskollektion",
		"reimo-conversion":"Reimo omvandling",
		"reimo-vehicles":"Reimo fordon",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 Tillbehör",
		"adria-caravans":"Husvagn Adria",
		"adria-rvs":"Husbilar Adria",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living husbilar",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Uthyrning av husbil",
		"caravans-rental":"Uthyrning av husvagn",    
		"sunday":"Söndag",
		"monday":"Måndag",
		"tuesday":"Tisdag",
		"wednesday":"Onsdag",
		"thursday":"Torsdag",
		"friday":"Fredag",
		"saturday":"Lördag",
		"geschlossen":"stängd",
		"nächstgelegener Händler":"närmaste återförsäljare",
		"Keine Händler gefunden.":"Inga handlare hittades.",
		"Wegbeschreibung":"Vägbeskrivning",
		"Reimo Zubehör Händler":"Återförsäljare av Reimo-tillbehör",
		"searchNearMe":"Sök i ditt område...",
		"couldNotGetLocation":"Position kunde inte fastställas.",
		"gettingLocation":"Viss position...",
		"Händlerliste":"Lista över återförsäljare",
		"Vergrößern":"Förstora",

		"Hinweis":"Anmärkning"
}
  },
  fi: {
    translation: {
		"Mein Standort":"Sijaintini",
		"Entfernung":"Etäisyys",
		"Fahrzeit (ca.)":"Matka-aika (noin)",
		"Zubehör":"Tarvikkeet",
		"Ausbau":"Laajennettu",
		"Reimo Fahrzeuge":"Reimo ajoneuvot",
		"Adria Wohnmobile, Vans & Caravans":"Adria matkailuautot, pakettiautot ja asuntovaunut",
		"Sun Living":"Sun Living",
		"Vermietung":"Vuokraus",
		"Lippert Händler":"Lippert-jälleenmyyjä",
		"rvs":"Matkailuautot",
		"caravans":"Asuntovaunu",
		"vans":"Pakettiautot",
		"service":"Palvelu",
		"conversion":"Muuntaminen",
		"installation":"Asennus",
		"accessories":"Tarvikekauppa",
		"reimo-accessories":"Reimo tarvikkeiden asiantuntija",
		"accessories-pickup":"Reimo tarvikemallisto",
		"reimo-conversion":"Reimo muuntaminen",
		"reimo-vehicles":"Reimo ajoneuvot",
		"adria-vans":"Adria pakettiautot",
		"camp4":"Camp4 tarvikkeet",
		"adria-caravans":"Adrian asuntovaunu",
		"adria-rvs":"Adria matkailuautot",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living matkailuautot",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Matkailuauton vuokraus",
		"caravans-rental":"Asuntovaunun vuokraus",
		"sunday":"Sunnuntai",
		"monday":"Maanantai",
		"tuesday":"Tiistai",
		"wednesday":"Keskiviikko",
		"thursday":"Torstai",
		"friday":"Perjantai",
		"saturday":"Lauantai",
		"geschlossen":"suljettu",
		"nächstgelegener Händler":"lähin jälleenmyyjä",
		"Keine Händler gefunden.":"Ei löytynyt kauppiaita.",
		"Wegbeschreibung":"Ohjeet",
		"Reimo Zubehör Händler":"Reimo tarvikkeiden jälleenmyyjät",
		"searchNearMe":"Etsi alueeltasi...",
		"couldNotGetLocation":"Asemaa ei voitu määrittää.",
		"gettingLocation":"Tietty asema...",
		"Händlerliste":"Jälleenmyyjien luettelo",
		"Vergrößern":"Suurenna",
		"Hinweis":"Huomautus"
}
  },
  no: {
    translation: {
      "Ausbau": "Bienvenue à React et react-i18next"
    }
  },
  tr: {
    translation: {
		"Mein Standort":"Benim konumum",
		"Entfernung":"Mesafe",
		"Fahrzeit (ca.)":"Seyahat süresi (yaklaşık)",
		"Zubehör":"Aksesuarlar",
		"Ausbau":"Genişletilmiş",
		"Reimo Fahrzeuge":"Reimo araçları",
		"Adria Wohnmobile, Vans & Caravans":"Adria Motorhomes, Vans & Caravans",
		"Sun Living":"Güneşte Yaşam",
		"Vermietung":"Kiralama",
		"Lippert Händler":"Lippert Bayi",
		"rvs":"Karavanlar",
		"caravans":"Karavan",
		"vans":"Vans",
		"service":"Hizmet",
		"conversion":"Dönüşüm",
		"installation":"Kurulum",
		"accessories":"Aksesuar ticareti",
		"reimo-accessories":"Reimo Aksesuar Uzmanı",
		"accessories-pickup":"Reimo aksesuar koleksiyonu",
		"reimo-conversion":"Reimo dönüşümü",
		"reimo-vehicles":"Reimo araçları",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 Aksesuarları",
		"adria-caravans":"Adria Karavan",
		"adria-rvs":"Adria karavanları",
		"adria-service":"Adria Servis",
		"sun-living":"Sun Living Karavanlar",
		"sun-living-service":"Güneş Yaşam Hizmeti",
		"rvs-rental":"Karavan kiralama",
		"caravans-rental":"Karavan kiralama",
		"sunday":"Pazar",
		"monday":"Pazartesi",
		"tuesday":"Salı",
		"wednesday":"Çarşamba",
		"thursday":"Perşembe",
		"friday":"Cuma",
		"saturday":"Cumartesi",
		"geschlossen":"kapalı",
		"nächstgelegener Händler":"en yakın bayi",
		"Keine Händler gefunden.":"Tüccar bulunamadı.",
		"Wegbeschreibung":"Yol Tarifi",
		"Reimo Zubehör Händler":"Reimo Aksesuar Bayileri",
		"searchNearMe":"Bölgenizde arama yapın...",
		"couldNotGetLocation":"Pozisyon belirlenememiştir.",
		"gettingLocation":"Belli bir pozisyon...",
		"Händlerliste":"Bayi listesi",
		"Vergrößern":"Büyüt",

		"Hinweis":"Not"
    }
  },
  ro: {
    translation: {
		"Mein Standort":"Locația mea",
		"Entfernung":"Distanța",
		"Fahrzeit (ca.)":"Durata călătoriei (aprox.)",
		"Zubehör":"Accesorii",
		"Ausbau":"Extinse",
		"Reimo Fahrzeuge":"Vehicule Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria Motorhome, furgonete și rulote",
		"Sun Living":"Sun Living",
		"Vermietung":"Închiriere",
		"Lippert Händler":"Lippert Dealer",
		"rvs":"Autovehicule cu motor",
		"caravans":"Caravana",
		"vans":"Vans",
		"service":"Serviciul",
		"conversion":"Conversie",
		"installation":"Instalare",
		"accessories":"Comerțul cu accesorii",
		"reimo-accessories":"Specialist în accesorii Reimo",
		"accessories-pickup":"Colecția de accesorii Reimo",
		"reimo-conversion":"Conversia Reimo",
		"reimo-vehicles":"Vehicule Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Accesorii Camp4",
		"adria-caravans":"Caravana Adria",
		"adria-rvs":"Autocaravane Adria",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living Motorhomes",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Închiriere de autoturisme",
		"caravans-rental":"Închiriere caravană",
		"sunday":"Duminică",
		"monday":"Luni",
		"tuesday":"Marți",
		"wednesday":"Miercuri",
		"thursday":"Joi",
		"friday":"Vineri",
		"saturday":"Sâmbătă",
		"geschlossen":"închisă",
		"nächstgelegener Händler":"cel mai apropiat distribuitor",
		"Keine Händler gefunden.":"Nu s-au găsit comercianți.",
		"Wegbeschreibung":"Direcții",
		"Reimo Zubehör Händler":"Distribuitori de accesorii Reimo",
		"searchNearMe":"Căutați în zona dumneavoastră...",
		"couldNotGetLocation":"Poziția nu a putut fi determinată.",
		"gettingLocation":"Anumite poziții...",
		"Händlerliste":"Lista dealerilor",
		"Vergrößern":"Extindeți",

		"Hinweis":"Notă"
    }
  },
  sk: {
    translation: {
		"Mein Standort":"Moja poloha",
		"Entfernung":"Vzdialenosť",
		"Fahrzeit (ca.)":"Čas cesty (približne)",
		"Zubehör":"Príslušenstvo",
		"Ausbau":"Rozšírené",
		"Reimo Fahrzeuge":"Vozidlá Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria Motorové vozidlá, dodávky a karavany",
		"Sun Living":"Sun Living",
		"Vermietung":"Prenájom",
		"Lippert Händler":"Predajca spoločnosti Lippert",
		"rvs":"Motorové vozidlá",
		"caravans":"Karavan",
		"vans":"Vans",
		"service":"Služba",
		"conversion":"Konverzia",
		"installation":"Inštalácia",
		"accessories":"Obchod s príslušenstvom",
		"reimo-accessories":"Špecialista na príslušenstvo Reimo",
		"accessories-pickup":"Kolekcia príslušenstva Reimo",
		"reimo-conversion":"Konverzia Reimo",
		"reimo-vehicles":"Vozidlá Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Príslušenstvo Camp4",
		"adria-caravans":"Karavan Adria",
		"adria-rvs":"Obytné automobily Adria",
		"adria-service":"Adria Service",
		"sun-living":"Obytné automobily Sun Living",
		"sun-living-service":"Služba Sun Living",
		"rvs-rental":"Prenájom obytných vozidiel",
		"caravans-rental":"Prenájom karavanov", 
		"sunday":"Nedeľa",
		"monday":"Pondelok",
		"tuesday":"Utorok",
		"wednesday":"Streda",
		"thursday":"Štvrtok",
		"friday":"Piatok",
		"saturday":"Sobota",
		"geschlossen":"zatvorené",
		"nächstgelegener Händler":"najbližší predajca",
		"Keine Händler gefunden.":"Nenašli sa žiadni obchodníci.",
		"Wegbeschreibung":"Inštrukcie",
		"Reimo Zubehör Händler":"Predajcovia príslušenstva Reimo",
	   	"searchNearMe":"Vyhľadávajte vo svojej oblasti...",
		"couldNotGetLocation":"Polohu nebolo možné určiť.",
		"gettingLocation":"Určitá pozícia...",
		"Händlerliste":"Zoznam predajcov",
		"Vergrößern":"Zväčšiť",
		"Hinweis":"Poznámka"
}
  },
  sl: {
    translation: {
		"Mein Standort":"Moja lokacija",
		"Entfernung":"Razdalja",
		"Fahrzeit (ca.)":"Čas potovanja (približno)",
		"Zubehör":"Dodatki",
		"Ausbau":"Razširjen",
		"Reimo Fahrzeuge":"Vozila Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria Avtodomi, avtodomi in prikolice",
		"Sun Living":"Družba Sun Living",
		"Vermietung":"Najem",
		"Lippert Händler":"Trgovec Lippert",
		"rvs":"Avtodomi",
		"caravans":"Prikolica",
		"vans":"Vans",
		"service":"Storitev",
		"conversion":"Pretvorba",
		"installation":"Namestitev",
		"accessories":"Trgovina z dodatki",
		"reimo-accessories":"Specialist za dodatke Reimo",
		"accessories-pickup":"Zbirka dodatkov Reimo",
		"reimo-conversion":"Pretvorba Reimo",
		"reimo-vehicles":"Vozila Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Dodatki za Camp4",
		"adria-caravans":"Prikolica Adria",
		"adria-rvs":"Avtodomi Adria",
		"adria-service":"Storitev Adria",
		"sun-living":"Avtodomi Sun Living",
		"sun-living-service":"Storitev Sun Living",
		"rvs-rental":"Najem avtodoma",
		"caravans-rental":"Najem prikolice",
		"sunday":"Nedelja",
		"monday":"Ponedeljek",
		"tuesday":"Torek",
		"wednesday":"Sreda",
		"thursday":"Četrtek",
		"friday":"Petek",
		"saturday":"Sobota",
		"geschlossen":"zaprta",
		"nächstgelegener Händler":"najbližji prodajalec",
		"Keine Händler gefunden.":"Ni bilo najdenih trgovcev.",
		"Wegbeschreibung":"Usmeritve",
		"Reimo Zubehör Händler":"Trgovci z dodatki Reimo",
		"searchNearMe":"Iščite v svojem območju...",
		"couldNotGetLocation":"Položaja ni bilo mogoče določiti.",
		"gettingLocation":"Določen položaj...",
		"Händlerliste":"Seznam trgovcev",
		"Vergrößern":"Povečajte",
		"Hinweis":"Opomba"
    }
  },
  ch: {
    translation: {
		"Mein Standort":"Mein Standort",
      	"Entfernung":"Entfernung",
      	"Fahrzeit (ca.)":"Fahrzeit (ca.)",
      	"Zubehör": "Zubehör",
      	"Ausbau": "Ausbau",
      	"Reimo Fahrzeuge": "Reimo Fahrzeuge",
      	"Adria Wohnmobile, Vans & Caravans": "Adria Wohnmobile, Vans & Caravans",
      	"Sun Living": "Sun Living",
      	"Vermietung":"Vermietung",	
      	"Lippert Händler": "Lippert Händler",
      	"rvs": "Wohnmobile",
  	  	"caravans": "Wohnwagen",
  		"vans": "Vans",
  		"service": "Service",
  		"conversion": "Umbau",
  		"installation": "Einbau",
  		"accessories": "Zubehör-Handel",
  		"reimo-accessories": "Reimo Zubehör Spezialist",
	  	"accessories-pickup": "Reimo Zubehör Abholung",
  		"reimo-conversion": "Reimo Umbau",
  		"reimo-vehicles": "Reimo Fahrzeuge",
  		"adria-vans": "Adria Vans",
  		"camp4": "Camp4 Zuebhör",
  		"adria-caravans": "Adria Wohnwagen",
  		"adria-rvs": "Adria Wohnmobile",
  		"adria-service": "Adria Service",
  		"sun-living": "Sun Living Wohnmobile",
  		"sun-living-service": "Sun Living Service",
  		"rvs-rental": "Wohnmobil-Vermietung",
  		"caravans-rental": "Wohnwagen-Vermietung",
  		"sunday":"Sonntag",
		"monday":"Montag",
		"tuesday":"Dienstag",
		"wednesday":"Mittwoch",
		"thursday":"Donnerstag",
		"friday":"Freitag",
		"saturday":"Samstag",
		"geschlossen": "geschlossen",   
		"nächstgelegener Händler": "nächstgelegener Händler",
		"Keine Händler gefunden.": "Keine Händler gefunden.",
		"Wegbeschreibung": "Wegbeschreibung"
		
  	}
  },
  ru: {
    translation: {
		"Mein Standort":"Мое местоположение",
		"Entfernung":"Расстояние",
		"Fahrzeit (ca.)":"Время в пути (приблизительно)",
		"Zubehör":"Аксессуары",
		"Ausbau":"Расширенный",
		"Reimo Fahrzeuge":"Транспортные средства Reimo",
		"Adria Wohnmobile, Vans & Caravans":"Adria Motorhomes, Vans & Caravans",
		"Sun Living":"Sun Living",
		"Vermietung":"Аренда",
		"Lippert Händler":"Дилер Lippert",
		"rvs":"Автодома",
		"caravans":"Караван",
		"vans":"Vans",
		"service":"Сервис",
		"conversion":"Конверсия",
		"installation":"Установка",
		"accessories":"Торговля аксессуарами",
		"reimo-accessories":"Специалист по аксессуарам Reimo",
		"accessories-pickup":"Коллекция аксессуаров Reimo",
		"reimo-conversion":"Преобразование Реймо",
		"reimo-vehicles":"Транспортные средства Reimo",
		"adria-vans":"Adria Vans",
		"camp4":"Аксессуары Camp4",
		"adria-caravans":"Караван Adria",
		"adria-rvs":"Автодома Adria",
		"adria-service":"Адриа Сервис",
		"sun-living":"Автодома Sun Living",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Аренда автодома",
		"caravans-rental":"Аренда караванов", 
		"sunday":"Воскресенье",
		"monday":"Понедельник",
		"tuesday":"Вторник",
		"wednesday":"Среда",
		"thursday":"Четверг",
		"friday":"Пятница",
		"saturday":"Суббота",
		"geschlossen":"закрытый",
		"nächstgelegener Händler":"ближайший дилер",
		"Keine Händler gefunden.":"Торговцев не найдено.",
		"Wegbeschreibung":"Направление",
		"Reimo Zubehör Händler":"Дилеры аксессуаров Reimo",
		"searchNearMe":"Поиск в вашем регионе...",
		"couldNotGetLocation":"Позицию определить не удалось.",
		"gettingLocation":"Определенная позиция...",
		"Händlerliste":"Список дилеров",
		"Hinweis":"Примечание"
}
  },
  at: {
    translation: {
		"Mein Standort":"Mein Standort",
      	"Entfernung":"Entfernung",
      	"Fahrzeit (ca.)":"Fahrzeit (ca.)",
      	"Zubehör": "Zubehör",
      	"Ausbau": "Ausbau",
      	"Reimo Fahrzeuge": "Reimo Fahrzeuge",
      	"Adria Wohnmobile, Vans & Caravans": "Adria Wohnmobile, Vans & Caravans",
      	"Sun Living": "Sun Living",
      	"Vermietung":"Vermietung",	
      	"Lippert Händler": "Lippert Händler",
      	"rvs": "Wohnmobile",
  	  	"caravans": "Wohnwagen",
  		"vans": "Vans",
  		"service": "Service",
  		"conversion": "Umbau",
  		"installation": "Einbau",
  		"accessories": "Zubehör-Handel",
  		"reimo-accessories": "Reimo Zubehör Spezialist",
	  	"accessories-pickup": "Reimo Zubehör Abholung",
  		"reimo-conversion": "Reimo Umbau",
  		"reimo-vehicles": "Reimo Fahrzeuge",
  		"adria-vans": "Adria Vans",
  		"camp4": "Camp4 Zuebhör",
  		"adria-caravans": "Adria Wohnwagen",
  		"adria-rvs": "Adria Wohnmobile",
  		"adria-service": "Adria Service",
  		"sun-living": "Sun Living Wohnmobile",
  		"sun-living-service": "Sun Living Service",
  		"rvs-rental": "Wohnmobil-Vermietung",
  		"caravans-rental": "Wohnwagen-Vermietung",
 		"sunday":"Sonntag",
		"monday":"Montag",
		"tuesday":"Dienstag",
		"wednesday":"Mittwoch",
		"thursday":"Donnerstag",
		"friday":"Freitag",
		"saturday":"Samstag",
		"geschlossen": "geschlossen",   
		"nächstgelegener Händler": "nächstgelegener Händler",
		"Keine Händler gefunden.": "Keine Händler gefunden.",
		"Wegbeschreibung": "Wegbeschreibung" 		
 }
  },
  be: {
    translation: {
      "Ausbau": "Bienvenue à React et react-i18next"
    }
  },
  et: {
    translation: {
		"Mein Standort":"Minu asukoht",
		"Entfernung":"Kaugus",
		"Fahrzeit (ca.)":"Reisiaeg (umbes)",
		"Zubehör":"Tarvikud",
		"Ausbau":"Laiendatud",
		"Reimo Fahrzeuge":"Reimo sõidukid",
		"Adria Wohnmobile, Vans & Caravans":"Adria matkaautod, haagissuvilad ja haagissuvilad",
		"Sun Living":"Sun Living",
		"Vermietung":"Rentimine",
		"Lippert Händler":"Lippert edasimüüja",
		"rvs":"Matkasõidukid",
		"caravans":"Haagissuvila",
		"vans":"Pakiautod",
		"service":"Teenus",
		"conversion":"Ümberehitus",
		"installation":"Paigaldamine",
		"accessories":"Aksessuaarikaubandus",
		"reimo-accessories":"Reimo tarvikute spetsialist",
		"accessories-pickup":"Reimo aksessuaaride kollektsioon",
		"reimo-conversion":"Reimo ümberehitus",
		"reimo-vehicles":"Reimo sõidukid",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 tarvikud",
		"adria-caravans":"Adria haagissuvila",
		"adria-rvs":"Adria matkaautod",
		"adria-service":"Adria teenus",
		"sun-living":"Sun Living Eluautod",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Eluaseme rentimine",
		"caravans-rental":"Karavanide rentimine", 
		"sunday":"Pühapäev",
		"monday":"Esmaspäev",
		"tuesday":"Teisipäev",
		"wednesday":"Kolmapäev",
		"thursday":"Neljapäev",
		"friday":"Reede",
		"saturday":"Laupäev",
		"geschlossen":"suletud",
		"nächstgelegener Händler":"lähim edasimüüja",
		"Keine Händler gefunden.":"Kauplejaid ei leitud.",
		"Wegbeschreibung":"Juhised",
		"Reimo Zubehör Händler":"Reimo tarvikute edasimüüjad",  
		"searchNearMe":"Otsi oma piirkonnas...",
		"couldNotGetLocation":"Asendit ei olnud võimalik kindlaks määrata.",
		"gettingLocation":"Teatud positsioon...",
		"Händlerliste":"Edasimüüjate nimekiri",
		"Vergrößern":"Suurenda",
		"Hinweis":"Märkus"	 
}
  },
  hu: {
    translation: {
		"Mein Standort":"Az én helyem",
		"Entfernung":"Távolság",
		"Fahrzeit (ca.)":"Utazási idő (kb.)",
		"Zubehör":"Tartozékok",
		"Ausbau":"Kibővített",
		"Reimo Fahrzeuge":"Reimo járművek",
		"Adria Wohnmobile, Vans & Caravans":"Adria lakóautók, lakóautók és lakókocsik",
		"Sun Living":"Sun Living",
		"Vermietung":"Bérlés",
		"Lippert Händler":"Lippert kereskedő",
		"rvs":"Lakóautók",
		"caravans":"Lakókocsi",
		"vans":"Vans",
		"service":"Szolgáltatás",
		"conversion":"Átalakítás",
		"installation":"Telepítés",
		"accessories":"Tartozékok kereskedelme",
		"reimo-accessories":"Reimo tartozékok szakértője",
		"accessories-pickup":"Reimo tartozék kollekció",
		"reimo-conversion":"Reimo átalakítás",
		"reimo-vehicles":"Reimo járművek",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 tartozékok",
		"adria-caravans":"Adria lakókocsi",
		"adria-rvs":"Adria lakóautók",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living lakóautók",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Lakóautó bérlés",
		"caravans-rental":"Lakókocsi bérlés",    
		"sunday":"Vasárnap",
		"monday":"Hétfő",
		"tuesday":"Kedd",
		"wednesday":"Szerda",
		"thursday":"Csütörtök",
		"friday":"Péntek",
		"saturday":"Szombat",
		"geschlossen":"zárt",
		"nächstgelegener Händler":"legközelebbi kereskedő",
		"Keine Händler gefunden.":"Nem találtak kereskedőket.",
		"Wegbeschreibung":"Útvonalter",
		"Reimo Zubehör Händler":"Reimo tartozékok kereskedői",
		"searchNearMe":"Keressen a környéken...",
		"couldNotGetLocation":"A pozíciót nem lehetett meghatározni.",
		"gettingLocation":"Bizonyos pozíció...",
		"Händlerliste":"Kereskedők listája",
		"Vergrößern":"Nagyítás",
		"Hinweis":"Megjegyzés:"
}
  },
  cs: {
    translation: {
      "Ausbau": "Bienvenue à React et react-i18next"
    }
  },
  lt: {
    translation: {
		"Mein Standort":"Mano buvimo vieta",
		"Entfernung":"Atstumas",
		"Fahrzeit (ca.)":"Kelionės laikas (apytiksliai)",
		"Zubehör":"Priedai",
		"Ausbau":"Išplėstas",
		"Reimo Fahrzeuge":"Reimo transporto priemonės",
		"Adria Wohnmobile, Vans & Caravans":"Adria nameliai ant ratų, furgonai ir karavanai",
		"Sun Living":"Sun Living",
		"Vermietung":"Nuoma",
		"Lippert Händler":"Lippert atstovas",
		"rvs":"Nameliai ant ratų",
		"caravans":"Karavanas",
		"vans":"Vans",
		"service":"Paslauga",
		"conversion":"Konversija",
		"installation":"Įrengimas",
		"accessories":"Prekyba priedais",
		"reimo-accessories":"Reimo priedų specialistas",
		"accessories-pickup":"Reimo aksesuarų kolekcija",
		"reimo-conversion":"Reimo konversija",
		"reimo-vehicles":"Reimo transporto priemonės",
		"adria-vans":"Adria Vans",
		"camp4":"Camp4 priedai",
		"adria-caravans":"Adria karavanas",
		"adria-rvs":"Adria kemperiai",
		"adria-service":"Adria Service",
		"sun-living":"Sun Living Motorhomes",
		"sun-living-service":"Sun Living Service",
		"rvs-rental":"Kemperių nuoma",
		"caravans-rental":"Karavanų nuoma", 
		"sunday":"Sekmadienis",
		"monday":"Pirmadienis",
		"tuesday":"Antradienis",
		"wednesday":"Trečiadienis",
		"thursday":"Ketvirtadienis",
		"friday":"Penktadienis",
		"saturday":"Šeštadienis",
		"geschlossen":"uždarytas",
		"nächstgelegener Händler":"artimiausias platintojas",
		"Keine Händler gefunden.":"Prekybininkų nerasta.",
		"Wegbeschreibung":"Kryptys",
		"Reimo Zubehör Händler":"Reimo priedų pardavėjai",
		"searchNearMe":"Ieškokite savo vietovėje...",
		"couldNotGetLocation":"Pozicijos nustatyti nepavyko.",
		"gettingLocation":"Tam tikros pozicijos...",
		"Händlerliste":"Pardavėjų sąrašas",
		"Vergrößern":"Padidinti",
		"Hinweis":"Pastaba"
}
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de", 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;