import * as React from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {useJsApiLoader, DistanceMatrixService } from '@react-google-maps/api';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import HomeIcon from '@mui/icons-material/Home';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useTranslation } from 'react-i18next';

export default  function DealerInfo(props) {
	
	const  isLoaded  = true;


	const { t, i18n } = useTranslation("",{lng: props.language});

	const [open, setOpen] = React.useState(props.highlightedDealer ?  true : false);
  	const [weekOpen, setWeekOpen] = React.useState(false);
	const [distance, setDistance] = React.useState(null);
	const [duration, setDuration] = React.useState(null);

  	const handleClickOpen = () => {
    	setOpen(true);
  	};
  	
  	const handleClickWeek = () => {
    	setWeekOpen(!weekOpen);
  	};

  	const handleClose = (value) => {
    	setOpen(false);
    	props.setHighlightedDealer(null);
  	};
	const days = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday'];

	(function() {
	    Date.prototype.getDayName = function() {
	        return days[ this.getDay() ];
	    };
	})();
		
	var day = new Date().getDayName();
	const printday = function(d) {	
      	if(props.highlightedDealer.opening[0][d].open) {
			return <span>{t(d)}: {props.highlightedDealer.opening[0][d].from} - {props.highlightedDealer.opening[0][d].to}</span>		  
		}
		else {
			return <span>{t(d)}: {t("geschlossen")}</span>
		}
	}

  	return props.highlightedDealer ? (
	 		<Dialog onClose={handleClose} open={props.highlightedDealer ?  true : false} sx={{
			      "& .MuiDialog-container": {
			        "& .MuiPaper-root": {
			          width: "100%",
			          minWidth: "400px",  
			        },
			      },
			    }}>
				<DistanceMatrixService
				 options={{
				           origins: [{lat:props.currentLocation.lat, lng:props.currentLocation.lng}],
				           destinations: [{lat:props.highlightedDealer.location.lat, lng:props.highlightedDealer.location.lng}],
				           travelMode: "DRIVING",
				         }}
				 callback = {(response) => {setDistance(response.rows[0].elements[0].distance.text);setDuration(response.rows[0].elements[0].duration.text)}}
				/>			    
	    		<DialogTitle>{props.highlightedDealer.name}</DialogTitle>
	    		        <IconButton
				          aria-label="close"
				          onClick={handleClose}
				          sx={{
				            position: 'absolute',
				            right: 8,
				            top: 8,
				            color: (theme) => theme.palette.grey[500],
				          }}
				        >
				          <CloseIcon />
				        </IconButton>
	    		 <DialogContent dividers>
		    		<Box sx={{ flexGrow: 1 }}>
		      			<Grid container spacing={2}>
		        			<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>	    		 
				    		 	<Typography gutterBottom>
									<List>
										<ListItem disablePadding>
							              <ListItemIcon>
							                <HomeIcon />
							              </ListItemIcon>
							              <ListItemText >
							              {props.highlightedDealer.street}
							              <br/>
							              {(props.highlightedDealer.zip + " " + props.highlightedDealer.city)}
										  </ListItemText>
							          </ListItem>
							          {props.highlightedDealer.opening ? (	    								
										  <>
											  <ListItemButton onClick={handleClickWeek} disablePadding sx={{paddingLeft: 0}}>
									              <ListItemIcon>
									                <ScheduleIcon />
									              </ListItemIcon>
									              <ListItemText  >
									              	{printday(day)}
									              	{weekOpen ? <ExpandLess /> : <ExpandMore />}
									              </ListItemText>
									          </ListItemButton>
											  <Collapse in={weekOpen} timeout="auto" unmountOnExit>
										        <List component="div" disablePadding>
													{days.map(d => (		
														day != d ? (												
														<ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
														   <ListItemText>
									              				{printday(d)}
														   </ListItemText>
														 </ListItem>
														 ):(<></>)
														))
													}
										        </List>
									      	</Collapse>							          
										</>
							          ) : (<></>) }
							          
									</List>           				
			          			</Typography>
				    		 	<Typography gutterBottom>
									<List>
									 {props.highlightedDealer.phone != "" ? (
											<ListItem disablePadding>
								              <ListItemIcon>
								                <LocalPhoneIcon />
								              </ListItemIcon>
								              <ListItemText  >
								              	<a href={("tel:" + props.highlightedDealer.phone)}>{props.highlightedDealer.phone}</a>
								              </ListItemText>
								          </ListItem>
								          ) :(<></>)
								      }
								      {props.highlightedDealer.email != "" ? (
								          <ListItem disablePadding>
								              <ListItemIcon>
								                <EmailIcon />
								              </ListItemIcon>
								              <ListItemText >
									              <a href={("mailto:" + props.highlightedDealer.email)}>{props.highlightedDealer.email}</a>
								              </ListItemText>
								          </ListItem>	
								          ) :(<></>)
								      }							              								
								      {props.highlightedDealer.url != "" ? (
										<ListItem disablePadding>
							              <ListItemIcon>
							                <LinkIcon />
							              </ListItemIcon>
							              <ListItemText  >
							              	<a href={("http://"+ props.highlightedDealer.url)} target="new">{props.highlightedDealer.url}</a>
							              </ListItemText>
							             </ListItem>	    
							          ) :(<></>)
								      }								
									</List>    
									<Button startIcon={<NearMeIcon/>} variant="contained" color="success" onClick={props.navigate} sx={{flexGrow: 1, display: {
										xs: 'block',
										sm: 'block',  
										md: 'none',  
										lg: 'none',  
										xl: 'none',  
										
										} }}>
										{t("Wegbeschreibung")}
									</Button>
       				
			          			</Typography>
		        			</Grid>
		        			<Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}>	   
								<List>
									<ListItem disablePadding>
						              <ListItemIcon>
						                <DirectionsCarIcon />
						              </ListItemIcon>
						              <ListItemText  >
						              	{t("Entfernung")}: {distance}
						              </ListItemText>
						          	</ListItem>	    								
									<ListItem disablePadding>
						              <ListItemIcon>
						                <TimelapseIcon/>
						              </ListItemIcon>
						              <ListItemText  >
						              	{t("Fahrzeit (ca.)")}: {duration}
						              </ListItemText>
						          	</ListItem>	    								
								</List>
								<Button startIcon={<NearMeIcon/>} variant="contained" color="success" onClick={props.navigate}>
									{t("Wegbeschreibung")}
								</Button>
		        			</Grid>
		      			</Grid>
		    		</Box> 
		    		<hr/>
	    		 	<Typography gutterBottom>
						<List>
           				{props.highlightedDealer.specs.map(s => (
				          <ListItem disablePadding>
				              <ListItemIcon>
				                <CheckIcon />
				              </ListItemIcon>
				              <ListItemText primary={t(s)} />
				          </ListItem>	    		 	
						))}
						</List>           			
          			</Typography>
	    		 </DialogContent>
	    	</Dialog>
	    	) : (<></>)
  ;
}