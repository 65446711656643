import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';


const GPSIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 561 561" style={{ display: 'inline-block', verticalAlign: '-2px', paddingRight: '0.5rem' }}>
        <path
            style={{ fill: 'white' }}
            d="M280.5 178.5c-56.1 0-102 45.9-102 102s45.9 102 102 102 102-45.9 102-102-45.9-102-102-102zM507.45 255C494.7 147.9 410.55 63.75 306 53.55V0h-51v53.55C147.9 63.75
        63.75 147.9 53.55
        255H0v51h53.55C66.3 413.1 150.45 497.25 255 507.45V561h51v-53.55C413.1 494.7 497.25 410.55 507.45 306H561v-51h-53.55zM280.5 459C181.05 459 102 379.95 102 280.5S181.05 102 280.5 102 459 181.05
        459 280.5 379.95 459 280.5 459z"
        />
    </svg>
);

export default function GeoLocationButton(props) {
	
	const { t, i18n } = useTranslation("",{lng: props.language});
	const STATE_INIT = 'init';
	const STATE_SEARCHING = 'searching';
	const STATE_ERROR = 'error';
	const STATE_FOUND = 'found';
	const [state, setState] = React.useState(STATE_INIT);	
	
	const search = () => {
        setState({ STATE_SEARCHING });
        navigator.geolocation.getCurrentPosition(
            (location) => {
                props.onFound(location);
                setState(STATE_FOUND);
            },
            () => setState(STATE_ERROR ),
        );
    };
	
	return	(
		<Button startIcon={<GPSIcon/>} variant="contained" color="success" onClick={search}>
                {{
                    [STATE_INIT]: t("searchNearMe"),
                    [STATE_FOUND]: t("searchNearMe"),
                    [STATE_ERROR]: t("couldNotGetLocation"),
                    [STATE_SEARCHING]: t("gettingLocation"),
                }[state]}
		</Button>		
		
	)
};
