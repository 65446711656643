import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Icon from "@mui/material/Icon";
import SvgIcon from "@mui/material/SvgIcon";

import { AdriaLogo, ConversionIcon, ReimoLogo, RentalLogo, ShoppingCartIcon, SunLivingLogo, LippertLogo } from './Icons';


function FilterBar(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	const [value, setValue] = React.useState(parseInt(props.currentFilter));

  	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    	setValue(newValue);
    	props.setCurrentFilter(newValue);
  	};
  	
  	const fixIconNames = function(i) {
		if(i == "AdriaLogo")
			return  <SvgIcon><AdriaLogo/></SvgIcon>
		if(i == "ConversionIcon")
			return  <SvgIcon><ConversionIcon/></SvgIcon>
		if(i == "ReimoLogo")
			return  <SvgIcon><ReimoLogo/></SvgIcon>
		if(i == "RentalLogo")
			return  <SvgIcon><RentalLogo/></SvgIcon>
		if(i == "ShoppingCartIcon")
			return  <SvgIcon><ShoppingCartIcon/></SvgIcon>
		if(i == "SunLivingLogo")
			return  <SvgIcon><SunLivingLogo/></SvgIcon>
		if(i == "LippertLogo")
			return  <SvgIcon><LippertLogo/></SvgIcon>
	  
	}
	
	const lightTheme = createTheme({
	    palette: {
	      type: "light",
	      primary: {
	        light: "#b2dfdb",
	        main: "#ffd23f",
	        dark: "#004d40"
	      }
	    }
	  });
  return (
	<ThemeProvider theme={lightTheme}>
		<Box sx={{
						  minWidth: {
							xs: 100,  
							sm: 300,  
							md: 400,  
							lg: 500,  
							xl: 800,  
						  },
					  }}>
	    	<Tabs
		        value={value}
		        onChange={handleChange}
		        variant="scrollable"
		        scrollButtons
		        allowScrollButtonsMobile
		        aria-label=""
		    >
		    {props.config.filterbar.map(f => (
				<Tab value={f.id} icon={fixIconNames(f.icon)} iconPosition="start" label={t(f.label)}/>
			))}
	      	</Tabs>
	    </Box>
    </ThemeProvider>
  );
}

export default (FilterBar);