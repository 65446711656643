import './App.css';

import React, {useState, useEffect, useRef} from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import i18n from "./components/i18n";

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Map from "./components/Map";

import Button from '@mui/material/Button';
import DealerInfo from "./components/DealerInfo";
import DealerList from "./components/DealerList";
import FilterBar from "./components/FilterBar";
import Geocode from "react-geocode";
import Geosuggest from '@ubilabs/react-geosuggest';
import styled, { createGlobalStyle } from 'styled-components';
import GeoLocationButton from './components/GeoLocationButton';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import config from './config/config.json';
import dealers from './db/dealers.json';

const indexedDealers = dealers.map((d, id) => ({ id, ...d }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function App() {
  	return (
    	<Router>
	  		<Inner />
    	</Router>
  );
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Inner() {
	let query = useQuery();



	const [currentDealers, setCurrentDealers] = React.useState([]);
	const [highlightedDealer, setHighlightedDealer] = React.useState(null);	
  	const [directions, setDirections] = React.useState(false);
	
	const [currentFilter, setCurrentFilter] = React.useState(query.get("filter") ?  query.get("filter") : config.initFilter);
	const [language, setLanguage] = React.useState(query.get("lng") ?  query.get("lng") : config.initLanguage);
	const [zoom, setZoom] = React.useState(query.get("zoom") ?  query.get("zoom") : 8);
  	const [advice, setAdvice] = React.useState(0);

	const [currentLocation, setCurrentLocation] = React.useState(({lat: config.initLocation.lat, lng: config.initLocation.lng}));
	  const [open, setOpen] = React.useState(false);
	
	  const handleClickOpen = () => {
	    setOpen(true);
	  };
	
	  const handleClose = () => {
	    setOpen(false);
	  };
	  
	Geocode.setLanguage(language);
	const directionsService = new window.google.maps.DirectionsService();
	
	const getAdress = React.useCallback(function callback()  {			  
		Geocode.fromLatLng(currentLocation.lat, currentLocation.lng).then(
		  (response) => {
		    const address = response.results[0].formatted_address;
		    setCurrentAdress(address);
		  },
		  (error) => {
		    console.error(error);
		  }
		);	
	})		
	
	const [currentAdress, setCurrentAdress] = React.useState(config.initLocation.address);	
  	
  	const _setAdvice = React.useCallback(function callback(value)  {			  
		setAdvice(value);
	})	
	
  	const _setHighlightedDealer = React.useCallback(function callback(dealer)  {			  
		setHighlightedDealer(dealer);
	})	
	
  	const _setCurrentDealers = React.useCallback(function callback(dealers)  {	
		setCurrentDealers(dealers);
	})	
	
  	const _setCurrentLocation = React.useCallback(function callback(location)  {
		setCurrentLocation(location);
	})	
	const _setCurrentFilter = React.useCallback(function callback(filter)  {	
		setCurrentFilter(filter);
	})		
	
	const doNavigate = React.useCallback(function callback(dealer)  {	
		if(!dealer.location)
			dealer = highlightedDealer;
		
    	const origin = { lat:  currentLocation.lat, lng: currentLocation.lng };
    	const destination = { lat: dealer.location.lat, lng:  dealer.location.lng};
		setHighlightedDealer(false);
	    directionsService.route(
	        {
	            origin: origin,
	            destination: destination,
	            travelMode: window.google.maps.TravelMode.DRIVING,
	            
	        },
	        (result, status) => {
	            if (status === window.google.maps.DirectionsStatus.OK) {
	               setDirections(result)
	            } else {
	                console.error(`error fetching directions ${result}`);
	            }
	        }
	    );		
	})	
	
	
	const handleGeolocation = React.useCallback(function callback({ coords: { latitude: lat, longitude: lng } })  {
		let location = {};
		location.lat = lat;
		location.lng = lng;
		setCurrentLocation(location);
		getAdress();
	})
	
	const filteredDealer = indexedDealers.filter(dealer => {
		let _dealer = null;
		config.filterbar.map(f => {
			if(f.id == currentFilter) {
				f.specs.map(s => {
					dealer.specs.map(ds => {				
						if(ds == s)
							_dealer	= dealer;									
					});			

				})				
			}			
			if(dealer.origin == "lippert" && config.useLippert == true)
				_dealer	= dealer;											
		})
		if(_dealer != null)
			return dealer;					
			
	});
	
	const { t, i18n } = useTranslation("",{lng: language});
	const geosuggestEl = useRef(null);

  	const onSuggestSelect = (suggest) => {  	
  		if(suggest)
	  		setCurrentLocation(({lat: suggest.location.lat, lng: suggest.location.lng}));
	}
  	return (
    	<>
    		<DealerInfo navigate={doNavigate} currentLocation={currentLocation} highlightedDealer={highlightedDealer} setHighlightedDealer={_setHighlightedDealer} language={language}/>
	  		<Box className="App-bg">	  
		  		<Box className="App-header">
		  			<Box sx={{
						  minWidth: {
							xs: 100,  
							sm: 300,  
							md: 400,  
							lg: 500,  
							xl: 800,  
						  },
					  }}>
  						<Box sx={{ flexGrow: 1 }}>
		      				<Grid container spacing={2}>
		        				<Grid item xs={12} sm={12} md={5} lg={5} xl={5}> 		 
	                    			<StyledGeoSuggest className="search" onSuggestSelect={onSuggestSelect} placeholder={currentAdress}  autoActivateFirstSuggest />
						        </Grid>
						        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
				                    <GeoLocationButton className="geosearch" onFound={handleGeolocation} language={language}/>
	  			
						        </Grid>
						 	</Grid>
						</Box>
						
						<Box sx={{flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
			        			>
									{config.useLippert ? (<><br/></>) : (<FilterBar config={config} currentFilter={currentFilter} setCurrentFilter={_setCurrentFilter} language={language}/>)}
			        			</Grid>
			      			</Grid>
			    		</Box>  
						<Box sx={{flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={2} sm={2} md={2} lg={2} xl={2}
			        			>
							   		<Button sx={{paddingTop: "16px", border: "none"}} variant="outlined" onClick={handleClickOpen}>
							        <MenuIcon/>
							      	</Button>
			        			</Grid>
			        			<Grid item xs={10} sm={10} md={10} lg={10} xl={10}
			        			>
									{config.useLippert ? (<><br/></>) : (<FilterBar config={config} currentFilter={currentFilter} setCurrentFilter={_setCurrentFilter} language={language}/>)}
			        			</Grid>
			      			</Grid>
			    		</Box>  
			    		
						<Box sx={{flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={0} sm={0} md={3} lg={3} xl={3}
			        			>
									<DealerList setHighlightedDealer={_setHighlightedDealer} navigate={doNavigate} dealers={currentDealers} language={language}/>
			        			</Grid>
			        			<Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ flexGrow: 1, minHeight: "400px" }}>
			        				<Map zoom={zoom} directions={directions} setAdvice={_setAdvice} advice={advice} currentDealers={currentDealers} setCurrentLocation={_setCurrentLocation} currentLocation={currentLocation} filter={currentFilter} filteredDealers={filteredDealer} currentDealerList={_setCurrentDealers} setHighlightedDealer={_setHighlightedDealer} language={language}/>		
			        			</Grid>
			      			</Grid>
			    		</Box>  
			    		
						<Box sx={{flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={12}  sx={{ flexGrow: 1, minHeight: "400px" }}>
			        				<Map zoom={zoom} directions={directions} setAdvice={_setAdvice} advice={advice} currentDealers={currentDealers} setCurrentLocation={_setCurrentLocation} currentLocation={currentLocation} filter={currentFilter} filteredDealers={filteredDealer} currentDealerList={_setCurrentDealers} setHighlightedDealer={_setHighlightedDealer} language={language}/>		
			        			</Grid>
			      			</Grid>
			    		</Box>  
  
			    		  	
		   			</Box>
      			</Box>
  			</Box>    
			<Dialog
		        fullScreen
		        open={open}
		        onClose={handleClose}
		        TransitionComponent={Transition}
		      >
		        <AppBar sx={{ position: 'relative' }}>
		          <Toolbar>
		            <IconButton
		              edge="start"
		              color="inherit"
		              onClick={handleClose}
		              aria-label="close"
		            >
		              <CloseIcon />
		            </IconButton>
		            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
		              {t('Händlerliste')}
		            </Typography>
		            
		          </Toolbar>
		        </AppBar>
		        <DealerList setHighlightedDealer={_setHighlightedDealer} navigate={doNavigate} dealers={currentDealers} language={language}/>

		      </Dialog>  			
  				
    	</>
  );
}

const StyledGeoSuggest = styled(Geosuggest)`
    font-size: 1rem;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    
    .geosuggest__input {
      width: 100%;
      border: 1px solid #e4dfeb;
      box-sizing: border-box;
      padding: .5rem .5rem;
      font-size: 16px;
      -webkit-transition: border 0.2s, box-shadow 0.2s;
      transition: border 0.2s, box-shadow 0.2s;
      border-radius: 2px;
      outline: none;
      background-color: #F7F7F7;
      font-family: 'Titillium Web', sans-serif;
    }
    .geosuggest__input:focus {
      border-color: #267dc0;
    }
    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 25em;
      padding: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.15);
      border-top-width: 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;
      -webkit-transition: max-height 0.2s, border 0.2s;
              transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }
    
    /**
     * A geosuggest item
     */
    .geosuggest__item {
      font-size: 16px;
      padding: .5em .65em;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #f5f5f5;
    }
    .geosuggest__item--active {
      background: #267dc0;
      color: #fff;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
`;

