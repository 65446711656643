import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import NearMeIcon from '@mui/icons-material/NearMe';

import { useTranslation } from 'react-i18next';

export default function DealerList(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	return (
		<>
	    	<List sx={{ width: '100%', maxWidth: 360, maxHeight: 400, bgcolor: 'background.paper', overflow: 'auto' }}>
	    		{props.dealers.map(d => (
		      		<>
			      		<ListItem alignItems="flex-start">

			        		<ListItemText
			          			primary={d.name}
			          			secondary={
						            <React.Fragment>
						              <Typography
						                sx={{ display: 'inline' }}
						                component="span"
						                variant="body2"
						                color="text.primary"
						              >
						               {(d.street + ", " + d.zip + " " + d.city)}
						              </Typography>
						              <br/>
						              
						              <hr/>
						              {d.specs.map(s => (
								         t(s) + ", "
									  ))}
						              <span style={{float: 'right'}}>
						              	<IconButton onClick={e=> props.navigate(d)}>
						              		<NearMeIcon />
						              	</IconButton>
						              	<IconButton onClick={e => props.setHighlightedDealer(d)}>
						              		<InfoIcon />
						              	</IconButton>
						              </span>
						            </React.Fragment>
								}
			       			 />
			      		</ListItem>
		      		<Divider variant="inset" component="li" />
		   			</>   		
	      		))}
    		</List>
    	</>
  );
}